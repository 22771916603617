(function () {
	'use strict';
	angular
		.module('app')
		.factory('accountSettings', [
			'utilities',
			'seedcodeCalendar',
			accountSettings,
		]);

	function accountSettings(utilities, seedcodeCalendar) {
		return {
			groupSettingsPopover: groupSettingsPopover,
		};

		/** @type {(callback: Function, message: string) => void} */
		function groupSettingsPopover(callback, message) {
			const user = seedcodeCalendar.get('user');

			const popover = {
				id: 'groupSettingsPopover',
				controller: 'AccountSettingsCtrl',
				container: $('body'),
				type: 'modal', // modal or popover
				width: 600,
				data: {
					user: user,
					loading: false,
					message: message,
				},
				dataTitle: 'data',
				destroy: true,
				onShow: '',
				onShown: '',
				onHide: '',
				onHidden: '',
				show: true,
				callback: callback,
			};

			utilities.popover(
				popover,
				'<div ng-include="\'app/settings/account/edit-group-settings.html\'"></div>'
			);
		}
	}
})();
